import _objectVisit from "object-visit";
import _mapVisit from "map-visit";
var exports = {};
var visit = _objectVisit;
var mapVisit = _mapVisit;

exports = function (collection, method, val) {
  var result;

  if (typeof val === "string" && method in collection) {
    var args = [].slice.call(arguments, 2);
    result = collection[method].apply(collection, args);
  } else if (Array.isArray(val)) {
    result = mapVisit.apply(null, arguments);
  } else {
    result = visit.apply(null, arguments);
  }

  if (typeof result !== "undefined") {
    return result;
  }

  return collection;
};

export default exports;